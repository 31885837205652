@font-face {
  font-family: "moonlabs";
  src: url("../font/moonlabs.eot");
  src: url("../font/moonlabs.eot?jk6mbq#iefix") format("embedded-opentype"),
      url("../font/moonlabs.woff2?jk6mbq") format("woff2"),
      url("../font/moonlabs.woff?jk6mbq") format("woff"),
      url("../font/moonlabs.ttf?jk6mbq") format("truetype"),
      url("../font/moonlabs.svg?jk6mbq#moonlabs") format("svg");
  font-style: normal;
  font-weight: 400;
}

/* .icon { */
[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  font-family: "moonlabs" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  vertical-align: -.125em;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-activity::before {
  content: "\ea01";
}
.icon-activity-heart::before {
  content: "\ea02";
}
.icon-airplay::before {
  content: "\ea03";
}
.icon-airpods::before {
  content: "\ea04";
}
.icon-alarm-clock::before {
  content: "\ea05";
}
.icon-alarm-clock-check::before {
  content: "\ea06";
}
.icon-alarm-clock-minus::before {
  content: "\ea07";
}
.icon-alarm-clock-off::before {
  content: "\ea08";
}
.icon-alarm-clock-plus::before {
  content: "\ea09";
}
.icon-alert-circle::before {
  content: "\ea0a";
}
.icon-alert-hexagon::before {
  content: "\ea0b";
}
.icon-alert-octagon::before {
  content: "\ea0c";
}
.icon-alert-square::before {
  content: "\ea0d";
}
.icon-alert-triangle::before {
  content: "\ea0e";
}
.icon-align-bottom-01::before {
  content: "\ea0f";
}
.icon-align-bottom-02::before {
  content: "\ea10";
}
.icon-align-center::before {
  content: "\ea11";
}
.icon-align-horizontal-centre-01::before {
  content: "\ea12";
}
.icon-align-horizontal-centre-02::before {
  content: "\ea13";
}
.icon-align-justify::before {
  content: "\ea14";
}
.icon-align-left::before {
  content: "\ea15";
}
.icon-align-left-01::before {
  content: "\ea16";
}
.icon-align-left-02::before {
  content: "\ea17";
}
.icon-align-right::before {
  content: "\ea18";
}
.icon-align-right-01::before {
  content: "\ea19";
}
.icon-align-right-02::before {
  content: "\ea1a";
}
.icon-align-top-arrow-01::before {
  content: "\ea1b";
}
.icon-align-top-arrow-02::before {
  content: "\ea1c";
}
.icon-align-vertical-center-01::before {
  content: "\ea1d";
}
.icon-align-vertical-center-02::before {
  content: "\ea1e";
}
.icon-anchor::before {
  content: "\ea1f";
}
.icon-annotation::before {
  content: "\ea20";
}
.icon-annotation-alert::before {
  content: "\ea21";
}
.icon-annotation-check::before {
  content: "\ea22";
}
.icon-annotation-dots::before {
  content: "\ea23";
}
.icon-annotation-heart::before {
  content: "\ea24";
}
.icon-annotation-info::before {
  content: "\ea25";
}
.icon-annotation-plus::before {
  content: "\ea26";
}
.icon-annotation-question::before {
  content: "\ea27";
}
.icon-annotation-x::before {
  content: "\ea28";
}
.icon-announcement-01::before {
  content: "\ea29";
}
.icon-announcement-02::before {
  content: "\ea2a";
}
.icon-announcement-03::before {
  content: "\ea2b";
}
.icon-archive::before {
  content: "\ea2c";
}
.icon-arrow-block-down::before {
  content: "\ea2d";
}
.icon-arrow-block-left::before {
  content: "\ea2e";
}
.icon-arrow-block-right::before {
  content: "\ea2f";
}
.icon-arrow-block-up::before {
  content: "\ea30";
}
.icon-arrow-circle-broken-down::before {
  content: "\ea31";
}
.icon-arrow-circle-broken-down-left::before {
  content: "\ea32";
}
.icon-arrow-circle-broken-down-right::before {
  content: "\ea33";
}
.icon-arrow-circle-broken-left::before {
  content: "\ea34";
}
.icon-arrow-circle-broken-right::before {
  content: "\ea35";
}
.icon-arrow-circle-broken-up::before {
  content: "\ea36";
}
.icon-arrow-circle-broken-up-left::before {
  content: "\ea37";
}
.icon-arrow-circle-broken-up-right::before {
  content: "\ea38";
}
.icon-arrow-circle-down::before {
  content: "\ea39";
}
.icon-arrow-circle-down-left::before {
  content: "\ea3a";
}
.icon-arrow-circle-down-right::before {
  content: "\ea3b";
}
.icon-arrow-circle-left::before {
  content: "\ea3c";
}
.icon-arrow-circle-right::before {
  content: "\ea3d";
}
.icon-arrow-circle-up::before {
  content: "\ea3e";
}
.icon-arrow-circle-up-left::before {
  content: "\ea3f";
}
.icon-arrow-circle-up-right::before {
  content: "\ea40";
}
.icon-arrow-down::before {
  content: "\ea41";
}
.icon-arrow-down-left::before {
  content: "\ea42";
}
.icon-arrow-down-right::before {
  content: "\ea43";
}
.icon-arrow-left::before {
  content: "\ea44";
}
.icon-arrow-narrow-down::before {
  content: "\ea45";
}
.icon-arrow-narrow-down-left::before {
  content: "\ea46";
}
.icon-arrow-narrow-down-right::before {
  content: "\ea47";
}
.icon-arrow-narrow-left::before {
  content: "\ea48";
}
.icon-arrow-narrow-right::before {
  content: "\ea49";
}
.icon-arrow-narrow-up::before {
  content: "\ea4a";
}
.icon-arrow-narrow-up-left::before {
  content: "\ea4b";
}
.icon-arrow-narrow-up-right::before {
  content: "\ea4c";
}
.icon-arrow-right::before {
  content: "\ea4d";
}
.icon-arrow-square-down::before {
  content: "\ea4e";
}
.icon-arrow-square-down-left::before {
  content: "\ea4f";
}
.icon-arrow-square-down-right::before {
  content: "\ea50";
}
.icon-arrow-square-left::before {
  content: "\ea51";
}
.icon-arrow-square-right::before {
  content: "\ea52";
}
.icon-arrow-square-up::before {
  content: "\ea53";
}
.icon-arrow-square-up-left::before {
  content: "\ea54";
}
.icon-arrow-square-up-right::before {
  content: "\ea55";
}
.icon-arrow-up::before {
  content: "\ea56";
}
.icon-arrow-up-left::before {
  content: "\ea57";
}
.icon-arrow-up-right::before {
  content: "\ea58";
}
.icon-arrows-down::before {
  content: "\ea59";
}
.icon-arrows-left::before {
  content: "\ea5a";
}
.icon-arrows-right::before {
  content: "\ea5b";
}
.icon-arrows-triangle::before {
  content: "\ea5c";
}
.icon-arrows-up::before {
  content: "\ea5d";
}
.icon-asterisk-01::before {
  content: "\ea5e";
}
.icon-asterisk-02::before {
  content: "\ea5f";
}
.icon-at-sign::before {
  content: "\ea60";
}
.icon-atom-01::before {
  content: "\ea61";
}
.icon-atom-02::before {
  content: "\ea62";
}
.icon-attachment-01::before {
  content: "\ea63";
}
.icon-attachment-02::before {
  content: "\ea64";
}
.icon-award-01::before {
  content: "\ea65";
}
.icon-award-02::before {
  content: "\ea66";
}
.icon-award-03::before {
  content: "\ea67";
}
.icon-award-04::before {
  content: "\ea68";
}
.icon-backpack::before {
  content: "\ea69";
}
.icon-bank::before {
  content: "\ea6a";
}
.icon-bank-note-01::before {
  content: "\ea6b";
}
.icon-bank-note-02::before {
  content: "\ea6c";
}
.icon-bank-note-03::before {
  content: "\ea6d";
}
.icon-bar-chart-01::before {
  content: "\ea6e";
}
.icon-bar-chart-02::before {
  content: "\ea6f";
}
.icon-bar-chart-03::before {
  content: "\ea70";
}
.icon-bar-chart-04::before {
  content: "\ea71";
}
.icon-bar-chart-05::before {
  content: "\ea72";
}
.icon-bar-chart-06::before {
  content: "\ea73";
}
.icon-bar-chart-07::before {
  content: "\ea74";
}
.icon-bar-chart-08::before {
  content: "\ea75";
}
.icon-bar-chart-09::before {
  content: "\ea76";
}
.icon-bar-chart-10::before {
  content: "\ea77";
}
.icon-bar-chart-11::before {
  content: "\ea78";
}
.icon-bar-chart-12::before {
  content: "\ea79";
}
.icon-bar-chart-circle-01::before {
  content: "\ea7a";
}
.icon-bar-chart-circle-02::before {
  content: "\ea7b";
}
.icon-bar-chart-circle-03::before {
  content: "\ea7c";
}
.icon-bar-chart-square-01::before {
  content: "\ea7d";
}
.icon-bar-chart-square-02::before {
  content: "\ea7e";
}
.icon-bar-chart-square-03::before {
  content: "\ea7f";
}
.icon-bar-chart-square-down::before {
  content: "\ea80";
}
.icon-bar-chart-square-minus::before {
  content: "\ea81";
}
.icon-bar-chart-square-plus::before {
  content: "\ea82";
}
.icon-bar-chart-square-up::before {
  content: "\ea83";
}
.icon-bar-line-chart::before {
  content: "\ea84";
}
.icon-battery-charging-01::before {
  content: "\ea85";
}
.icon-battery-charging-02::before {
  content: "\ea86";
}
.icon-battery-empty::before {
  content: "\ea87";
}
.icon-battery-full::before {
  content: "\ea88";
}
.icon-battery-low::before {
  content: "\ea89";
}
.icon-battery-mid::before {
  content: "\ea8a";
}
.icon-beaker-01::before {
  content: "\ea8b";
}
.icon-beaker-02::before {
  content: "\ea8c";
}
.icon-bell-01::before {
  content: "\ea8d";
}
.icon-bell-02::before {
  content: "\ea8e";
}
.icon-bell-03::before {
  content: "\ea8f";
}
.icon-bell-04::before {
  content: "\ea90";
}
.icon-bell-minus::before {
  content: "\ea91";
}
.icon-bell-off-01::before {
  content: "\ea92";
}
.icon-bell-off-02::before {
  content: "\ea93";
}
.icon-bell-off-03::before {
  content: "\ea94";
}
.icon-bell-plus::before {
  content: "\ea95";
}
.icon-bell-ringing-01::before {
  content: "\ea96";
}
.icon-bell-ringing-02::before {
  content: "\ea97";
}
.icon-bell-ringing-03::before {
  content: "\ea98";
}
.icon-bell-ringing-04::before {
  content: "\ea99";
}
.icon-bezier-curve-01::before {
  content: "\ea9a";
}
.icon-bezier-curve-02::before {
  content: "\ea9b";
}
.icon-bezier-curve-03::before {
  content: "\ea9c";
}
.icon-bluetooth::before {
  content: "\ea9d";
}
.icon-bluetooth-connect::before {
  content: "\ea9e";
}
.icon-bluetooth-on::before {
  content: "\ea9f";
}
.icon-bluetooth-signal::before {
  content: "\eaa0";
}
.icon-bold-01::before {
  content: "\eaa1";
}
.icon-bold-02::before {
  content: "\eaa2";
}
.icon-bold-square::before {
  content: "\eaa3";
}
.icon-book-closed::before {
  content: "\eaa4";
}
.icon-book-open-01::before {
  content: "\eaa5";
}
.icon-book-open-02::before {
  content: "\eaa6";
}
.icon-bookmark::before {
  content: "\eaa7";
}
.icon-bookmark-add::before {
  content: "\eaa8";
}
.icon-bookmark-check::before {
  content: "\eaa9";
}
.icon-bookmark-minus::before {
  content: "\eaaa";
}
.icon-bookmark-x::before {
  content: "\eaab";
}
.icon-box::before {
  content: "\eaac";
}
.icon-brackets::before {
  content: "\eaad";
}
.icon-brackets-check::before {
  content: "\eaae";
}
.icon-brackets-ellipses::before {
  content: "\eaaf";
}
.icon-brackets-minus::before {
  content: "\eab0";
}
.icon-brackets-plus::before {
  content: "\eab1";
}
.icon-brackets-slash::before {
  content: "\eab2";
}
.icon-brackets-x::before {
  content: "\eab3";
}
.icon-briefcase-01::before {
  content: "\eab4";
}
.icon-briefcase-02::before {
  content: "\eab5";
}
.icon-browser::before {
  content: "\eab6";
}
.icon-brush-01::before {
  content: "\eab7";
}
.icon-brush-02::before {
  content: "\eab8";
}
.icon-brush-03::before {
  content: "\eab9";
}
.icon-building-01::before {
  content: "\eaba";
}
.icon-building-02::before {
  content: "\eabb";
}
.icon-building-03::before {
  content: "\eabc";
}
.icon-building-04::before {
  content: "\eabd";
}
.icon-building-05::before {
  content: "\eabe";
}
.icon-building-06::before {
  content: "\eabf";
}
.icon-building-07::before {
  content: "\eac0";
}
.icon-building-08::before {
  content: "\eac1";
}
.icon-bus::before {
  content: "\eac2";
}
.icon-calculator::before {
  content: "\eac3";
}
.icon-calendar::before {
  content: "\eac4";
}
.icon-calendar-check-01::before {
  content: "\eac5";
}
.icon-calendar-check-02::before {
  content: "\eac6";
}
.icon-calendar-date::before {
  content: "\eac7";
}
.icon-calendar-heart-01::before {
  content: "\eac8";
}
.icon-calendar-heart-02::before {
  content: "\eac9";
}
.icon-calendar-minus-01::before {
  content: "\eaca";
}
.icon-calendar-minus-02::before {
  content: "\eacb";
}
.icon-calendar-plus-01::before {
  content: "\eacc";
}
.icon-calendar-plus-02::before {
  content: "\eacd";
}
.icon-camera-01::before {
  content: "\eace";
}
.icon-camera-02::before {
  content: "\eacf";
}
.icon-camera-03::before {
  content: "\ead0";
}
.icon-camera-lens::before {
  content: "\ead1";
}
.icon-camera-off::before {
  content: "\ead2";
}
.icon-camera-plus::before {
  content: "\ead3";
}
.icon-certificate-01::before {
  content: "\ead4";
}
.icon-certificate-02::before {
  content: "\ead5";
}
.icon-chart-breakout-circle::before {
  content: "\ead6";
}
.icon-chart-breakout-square::before {
  content: "\ead7";
}
.icon-check::before {
  content: "\ead8";
}
.icon-check-circle::before {
  content: "\ead9";
}
.icon-check-circle-broken::before {
  content: "\eada";
}
.icon-check-done-01::before {
  content: "\eadb";
}
.icon-check-done-02::before {
  content: "\eadc";
}
.icon-check-heart::before {
  content: "\eadd";
}
.icon-check-square::before {
  content: "\eade";
}
.icon-check-square-broken::before {
  content: "\eadf";
}
.icon-check-verified-01::before {
  content: "\eae0";
}
.icon-check-verified-02::before {
  content: "\eae1";
}
.icon-chevron-down::before {
  content: "\eae2";
}
.icon-chevron-down-double::before {
  content: "\eae3";
}
.icon-chevron-left::before {
  content: "\eae4";
}
.icon-chevron-left-double::before {
  content: "\eae5";
}
.icon-chevron-right::before {
  content: "\eae6";
}
.icon-chevron-right-double::before {
  content: "\eae7";
}
.icon-chevron-selector-horizontal::before {
  content: "\eae8";
}
.icon-chevron-selector-vertical::before {
  content: "\eae9";
}
.icon-chevron-up::before {
  content: "\eaea";
}
.icon-chevron-up-double::before {
  content: "\eaeb";
}
.icon-chrome-cast::before {
  content: "\eaec";
}
.icon-circle::before {
  content: "\eaed";
}
.icon-clapperboard::before {
  content: "\eaee";
}
.icon-clipboard::before {
  content: "\eaef";
}
.icon-clipboard-attachment::before {
  content: "\eaf0";
}
.icon-clipboard-check::before {
  content: "\eaf1";
}
.icon-clipboard-download::before {
  content: "\eaf2";
}
.icon-clipboard-minus::before {
  content: "\eaf3";
}
.icon-clipboard-plus::before {
  content: "\eaf4";
}
.icon-clipboard-x::before {
  content: "\eaf5";
}
.icon-clock::before {
  content: "\eaf6";
}
.icon-clock-check::before {
  content: "\eaf7";
}
.icon-clock-fast-forward::before {
  content: "\eaf8";
}
.icon-clock-plus::before {
  content: "\eaf9";
}
.icon-clock-refresh::before {
  content: "\eafa";
}
.icon-clock-rewind::before {
  content: "\eafb";
}
.icon-clock-snooze::before {
  content: "\eafc";
}
.icon-clock-stopwatch::before {
  content: "\eafd";
}
.icon-cloud-01::before {
  content: "\eafe";
}
.icon-cloud-02::before {
  content: "\eaff";
}
.icon-cloud-03::before {
  content: "\eb00";
}
.icon-cloud-blank-01::before {
  content: "\eb01";
}
.icon-cloud-blank-02::before {
  content: "\eb02";
}
.icon-cloud-lightning::before {
  content: "\eb03";
}
.icon-cloud-moon::before {
  content: "\eb04";
}
.icon-cloud-off::before {
  content: "\eb05";
}
.icon-cloud-raining-01::before {
  content: "\eb06";
}
.icon-cloud-raining-02::before {
  content: "\eb07";
}
.icon-cloud-raining-03::before {
  content: "\eb08";
}
.icon-cloud-raining-04::before {
  content: "\eb09";
}
.icon-cloud-raining-05::before {
  content: "\eb0a";
}
.icon-cloud-raining-06::before {
  content: "\eb0b";
}
.icon-cloud-snowing-01::before {
  content: "\eb0c";
}
.icon-cloud-snowing-02::before {
  content: "\eb0d";
}
.icon-cloud-sun-01::before {
  content: "\eb0e";
}
.icon-cloud-sun-02::before {
  content: "\eb0f";
}
.icon-cloud-sun-03::before {
  content: "\eb10";
}
.icon-code-01::before {
  content: "\eb11";
}
.icon-code-02::before {
  content: "\eb12";
}
.icon-code-browser::before {
  content: "\eb13";
}
.icon-code-circle-01::before {
  content: "\eb14";
}
.icon-code-circle-02::before {
  content: "\eb15";
}
.icon-code-circle-03::before {
  content: "\eb16";
}
.icon-code-snippet-01::before {
  content: "\eb17";
}
.icon-code-snippet-02::before {
  content: "\eb18";
}
.icon-code-square-01::before {
  content: "\eb19";
}
.icon-code-square-02::before {
  content: "\eb1a";
}
.icon-codepen::before {
  content: "\eb1b";
}
.icon-coins-01::before {
  content: "\eb1c";
}
.icon-coins-02::before {
  content: "\eb1d";
}
.icon-coins-03::before {
  content: "\eb1e";
}
.icon-coins-04::before {
  content: "\eb1f";
}
.icon-coins-hand::before {
  content: "\eb20";
}
.icon-coins-stacked-01::before {
  content: "\eb21";
}
.icon-coins-stacked-02::before {
  content: "\eb22";
}
.icon-coins-stacked-03::before {
  content: "\eb23";
}
.icon-coins-stacked-04::before {
  content: "\eb24";
}
.icon-coins-swap-01::before {
  content: "\eb25";
}
.icon-coins-swap-02::before {
  content: "\eb26";
}
.icon-colors::before {
  content: "\eb27";
}
.icon-colors-1::before {
  content: "\eb28";
}
.icon-columns-02::before {
  content: "\eb29";
}
.icon-columns-03::before {
  content: "\eb2a";
}
.icon-command::before {
  content: "\eb2b";
}
.icon-compass::before {
  content: "\eb2c";
}
.icon-compass-01::before {
  content: "\eb2d";
}
.icon-compass-02::before {
  content: "\eb2e";
}
.icon-compass-03::before {
  content: "\eb2f";
}
.icon-container::before {
  content: "\eb30";
}
.icon-contrast-01::before {
  content: "\eb31";
}
.icon-contrast-02::before {
  content: "\eb32";
}
.icon-contrast-03::before {
  content: "\eb33";
}
.icon-copy-01::before {
  content: "\eb34";
}
.icon-copy-02::before {
  content: "\eb35";
}
.icon-copy-03::before {
  content: "\eb36";
}
.icon-copy-04::before {
  content: "\eb37";
}
.icon-copy-05::before {
  content: "\eb38";
}
.icon-copy-06::before {
  content: "\eb39";
}
.icon-copy-07::before {
  content: "\eb3a";
}
.icon-corner-down-left::before {
  content: "\eb3b";
}
.icon-corner-down-right::before {
  content: "\eb3c";
}
.icon-corner-left-down::before {
  content: "\eb3d";
}
.icon-corner-left-up::before {
  content: "\eb3e";
}
.icon-corner-right-down::before {
  content: "\eb3f";
}
.icon-corner-right-up::before {
  content: "\eb40";
}
.icon-corner-up-left::before {
  content: "\eb41";
}
.icon-corner-up-right::before {
  content: "\eb42";
}
.icon-cpu-chip-01::before {
  content: "\eb43";
}
.icon-cpu-chip-02::before {
  content: "\eb44";
}
.icon-credit-card-01::before {
  content: "\eb45";
}
.icon-credit-card-02::before {
  content: "\eb46";
}
.icon-credit-card-check::before {
  content: "\eb47";
}
.icon-credit-card-down::before {
  content: "\eb48";
}
.icon-credit-card-download::before {
  content: "\eb49";
}
.icon-credit-card-edit::before {
  content: "\eb4a";
}
.icon-credit-card-lock::before {
  content: "\eb4b";
}
.icon-credit-card-minus::before {
  content: "\eb4c";
}
.icon-credit-card-plus::before {
  content: "\eb4d";
}
.icon-credit-card-refresh::before {
  content: "\eb4e";
}
.icon-credit-card-search::before {
  content: "\eb4f";
}
.icon-credit-card-shield::before {
  content: "\eb50";
}
.icon-credit-card-up::before {
  content: "\eb51";
}
.icon-credit-card-upload::before {
  content: "\eb52";
}
.icon-credit-card-x::before {
  content: "\eb53";
}
.icon-crop-01::before {
  content: "\eb54";
}
.icon-crop-02::before {
  content: "\eb55";
}
.icon-cryptocurrency-01::before {
  content: "\eb56";
}
.icon-cryptocurrency-02::before {
  content: "\eb57";
}
.icon-cryptocurrency-03::before {
  content: "\eb58";
}
.icon-cryptocurrency-04::before {
  content: "\eb59";
}
.icon-cube-01::before {
  content: "\eb5a";
}
.icon-cube-02::before {
  content: "\eb5b";
}
.icon-cube-03::before {
  content: "\eb5c";
}
.icon-cube-04::before {
  content: "\eb5d";
}
.icon-cube-outline::before {
  content: "\eb5e";
}
.icon-currency-bitcoin::before {
  content: "\eb5f";
}
.icon-currency-bitcoin-circle::before {
  content: "\eb60";
}
.icon-currency-dollar::before {
  content: "\eb61";
}
.icon-currency-dollar-circle::before {
  content: "\eb62";
}
.icon-currency-ethereum::before {
  content: "\eb63";
}
.icon-currency-ethereum-circle::before {
  content: "\eb64";
}
.icon-currency-euro::before {
  content: "\eb65";
}
.icon-currency-euro-circle::before {
  content: "\eb66";
}
.icon-currency-pound::before {
  content: "\eb67";
}
.icon-currency-pound-circle::before {
  content: "\eb68";
}
.icon-currency-ruble::before {
  content: "\eb69";
}
.icon-currency-ruble-circle::before {
  content: "\eb6a";
}
.icon-currency-rupee::before {
  content: "\eb6b";
}
.icon-currency-rupee-circle::before {
  content: "\eb6c";
}
.icon-currency-yen::before {
  content: "\eb6d";
}
.icon-currency-yen-circle::before {
  content: "\eb6e";
}
.icon-cursor-01::before {
  content: "\eb6f";
}
.icon-cursor-02::before {
  content: "\eb70";
}
.icon-cursor-03::before {
  content: "\eb71";
}
.icon-cursor-04::before {
  content: "\eb72";
}
.icon-cursor-box::before {
  content: "\eb73";
}
.icon-cursor-click-01::before {
  content: "\eb74";
}
.icon-cursor-click-02::before {
  content: "\eb75";
}
.icon-data::before {
  content: "\eb76";
}
.icon-database-01::before {
  content: "\eb77";
}
.icon-database-02::before {
  content: "\eb78";
}
.icon-database-03::before {
  content: "\eb79";
}
.icon-dataflow-01::before {
  content: "\eb7a";
}
.icon-dataflow-02::before {
  content: "\eb7b";
}
.icon-dataflow-03::before {
  content: "\eb7c";
}
.icon-dataflow-04::before {
  content: "\eb7d";
}
.icon-delete::before {
  content: "\eb7e";
}
.icon-diamond-01::before {
  content: "\eb7f";
}
.icon-diamond-02::before {
  content: "\eb80";
}
.icon-dice-1::before {
  content: "\eb81";
}
.icon-dice-2::before {
  content: "\eb82";
}
.icon-dice-3::before {
  content: "\eb83";
}
.icon-dice-4::before {
  content: "\eb84";
}
.icon-dice-5::before {
  content: "\eb85";
}
.icon-dice-6::before {
  content: "\eb86";
}
.icon-disc-01::before {
  content: "\eb87";
}
.icon-disc-02::before {
  content: "\eb88";
}
.icon-discord::before {
  content: "\eb89";
}
.icon-distribute-spacing-horizontal::before {
  content: "\eb8a";
}
.icon-distribute-spacing-vertical::before {
  content: "\eb8b";
}
.icon-divide-01::before {
  content: "\eb8c";
}
.icon-divide-02::before {
  content: "\eb8d";
}
.icon-divide-03::before {
  content: "\eb8e";
}
.icon-divider::before {
  content: "\eb8f";
}
.icon-dotpoints-01::before {
  content: "\eb90";
}
.icon-dotpoints-02::before {
  content: "\eb91";
}
.icon-dots-grid::before {
  content: "\eb92";
}
.icon-dots-horizontal::before {
  content: "\eb93";
}
.icon-dots-vertical::before {
  content: "\eb94";
}
.icon-download-01::before {
  content: "\eb95";
}
.icon-download-02::before {
  content: "\eb96";
}
.icon-download-03::before {
  content: "\eb97";
}
.icon-download-04::before {
  content: "\eb98";
}
.icon-download-cloud-01::before {
  content: "\eb99";
}
.icon-download-cloud-02::before {
  content: "\eb9a";
}
.icon-drop::before {
  content: "\eb9b";
}
.icon-droplets-01::before {
  content: "\eb9c";
}
.icon-droplets-02::before {
  content: "\eb9d";
}
.icon-droplets-03::before {
  content: "\eb9e";
}
.icon-dropper::before {
  content: "\eb9f";
}
.icon-edit-01::before {
  content: "\eba0";
}
.icon-edit-02::before {
  content: "\eba1";
}
.icon-edit-03::before {
  content: "\eba2";
}
.icon-edit-04::before {
  content: "\eba3";
}
.icon-edit-05::before {
  content: "\eba4";
}
.icon-equal::before {
  content: "\eba5";
}
.icon-equal-not::before {
  content: "\eba6";
}
.icon-eraser::before {
  content: "\eba7";
}
.icon-expand-01::before {
  content: "\eba8";
}
.icon-expand-02::before {
  content: "\eba9";
}
.icon-expand-03::before {
  content: "\ebaa";
}
.icon-expand-04::before {
  content: "\ebab";
}
.icon-expand-05::before {
  content: "\ebac";
}
.icon-expand-06::before {
  content: "\ebad";
}
.icon-eye::before {
  content: "\ebae";
}
.icon-eye-off::before {
  content: "\ebaf";
}
.icon-face-content::before {
  content: "\ebb0";
}
.icon-face-frown::before {
  content: "\ebb1";
}
.icon-face-happy::before {
  content: "\ebb2";
}
.icon-face-id::before {
  content: "\ebb3";
}
.icon-face-id-square::before {
  content: "\ebb4";
}
.icon-face-neutral::before {
  content: "\ebb5";
}
.icon-face-sad::before {
  content: "\ebb6";
}
.icon-face-smile::before {
  content: "\ebb7";
}
.icon-face-wink::before {
  content: "\ebb8";
}
.icon-fast-backward::before {
  content: "\ebb9";
}
.icon-fast-forward::before {
  content: "\ebba";
}
.icon-feather::before {
  content: "\ebbb";
}
.icon-figma::before {
  content: "\ebbc";
}
.icon-file-01::before {
  content: "\ebbd";
}
.icon-file-02::before {
  content: "\ebbe";
}
.icon-file-03::before {
  content: "\ebbf";
}
.icon-file-04::before {
  content: "\ebc0";
}
.icon-file-05::before {
  content: "\ebc1";
}
.icon-file-06::before {
  content: "\ebc2";
}
.icon-file-07::before {
  content: "\ebc3";
}
.icon-file-attachment-01::before {
  content: "\ebc4";
}
.icon-file-attachment-02::before {
  content: "\ebc5";
}
.icon-file-attachment-03::before {
  content: "\ebc6";
}
.icon-file-attachment-04::before {
  content: "\ebc7";
}
.icon-file-attachment-05::before {
  content: "\ebc8";
}
.icon-file-check-01::before {
  content: "\ebc9";
}
.icon-file-check-02::before {
  content: "\ebca";
}
.icon-file-check-03::before {
  content: "\ebcb";
}
.icon-file-code-01::before {
  content: "\ebcc";
}
.icon-file-code-02::before {
  content: "\ebcd";
}
.icon-file-download-01::before {
  content: "\ebce";
}
.icon-file-download-02::before {
  content: "\ebcf";
}
.icon-file-download-03::before {
  content: "\ebd0";
}
.icon-file-heart-01::before {
  content: "\ebd1";
}
.icon-file-heart-02::before {
  content: "\ebd2";
}
.icon-file-heart-03::before {
  content: "\ebd3";
}
.icon-file-lock-01::before {
  content: "\ebd4";
}
.icon-file-lock-02::before {
  content: "\ebd5";
}
.icon-file-lock-03::before {
  content: "\ebd6";
}
.icon-file-minus-01::before {
  content: "\ebd7";
}
.icon-file-minus-02::before {
  content: "\ebd8";
}
.icon-file-minus-03::before {
  content: "\ebd9";
}
.icon-file-plus-01::before {
  content: "\ebda";
}
.icon-file-plus-02::before {
  content: "\ebdb";
}
.icon-file-plus-03::before {
  content: "\ebdc";
}
.icon-file-question-01::before {
  content: "\ebdd";
}
.icon-file-question-02::before {
  content: "\ebde";
}
.icon-file-question-03::before {
  content: "\ebdf";
}
.icon-file-search-01::before {
  content: "\ebe0";
}
.icon-file-search-02::before {
  content: "\ebe1";
}
.icon-file-search-03::before {
  content: "\ebe2";
}
.icon-file-shield-01::before {
  content: "\ebe3";
}
.icon-file-shield-02::before {
  content: "\ebe4";
}
.icon-file-shield-03::before {
  content: "\ebe5";
}
.icon-file-x-01::before {
  content: "\ebe6";
}
.icon-file-x-02::before {
  content: "\ebe7";
}
.icon-file-x-03::before {
  content: "\ebe8";
}
.icon-film-01::before {
  content: "\ebe9";
}
.icon-film-02::before {
  content: "\ebea";
}
.icon-film-03::before {
  content: "\ebeb";
}
.icon-filter-funnel-01::before {
  content: "\ebec";
}
.icon-filter-funnel-02::before {
  content: "\ebed";
}
.icon-filter-lines::before {
  content: "\ebee";
}
.icon-fingerprint-01::before {
  content: "\ebef";
}
.icon-fingerprint-02::before {
  content: "\ebf0";
}
.icon-fingerprint-03::before {
  content: "\ebf1";
}
.icon-fingerprint-04::before {
  content: "\ebf2";
}
.icon-flag-01::before {
  content: "\ebf3";
}
.icon-flag-02::before {
  content: "\ebf4";
}
.icon-flag-03::before {
  content: "\ebf5";
}
.icon-flag-04::before {
  content: "\ebf6";
}
.icon-flag-05::before {
  content: "\ebf7";
}
.icon-flag-06::before {
  content: "\ebf8";
}
.icon-flash::before {
  content: "\ebf9";
}
.icon-flash-off::before {
  content: "\ebfa";
}
.icon-flex-align-bottom::before {
  content: "\ebfb";
}
.icon-flex-align-left::before {
  content: "\ebfc";
}
.icon-flex-align-right::before {
  content: "\ebfd";
}
.icon-flex-align-top::before {
  content: "\ebfe";
}
.icon-flip-backward::before {
  content: "\ebff";
}
.icon-flip-forward::before {
  content: "\ec00";
}
.icon-folder::before {
  content: "\ec01";
}
.icon-folder-check::before {
  content: "\ec02";
}
.icon-folder-closed::before {
  content: "\ec03";
}
.icon-folder-code::before {
  content: "\ec04";
}
.icon-folder-download::before {
  content: "\ec05";
}
.icon-folder-lock::before {
  content: "\ec06";
}
.icon-folder-minus::before {
  content: "\ec07";
}
.icon-folder-plus::before {
  content: "\ec08";
}
.icon-folder-question::before {
  content: "\ec09";
}
.icon-folder-search::before {
  content: "\ec0a";
}
.icon-folder-shield::before {
  content: "\ec0b";
}
.icon-folder-x::before {
  content: "\ec0c";
}
.icon-frame-11::before {
  content: "\ec0d";
}
.icon-framer::before {
  content: "\ec0e";
}
.icon-gaming-pad-01::before {
  content: "\ec0f";
}
.icon-gaming-pad-02::before {
  content: "\ec10";
}
.icon-gift-01::before {
  content: "\ec11";
}
.icon-gift-02::before {
  content: "\ec12";
}
.icon-git-branch-01::before {
  content: "\ec13";
}
.icon-git-branch-02::before {
  content: "\ec14";
}
.icon-git-commit::before {
  content: "\ec15";
}
.icon-git-merge::before {
  content: "\ec16";
}
.icon-git-pull-request::before {
  content: "\ec17";
}
.icon-glasses-01::before {
  content: "\ec18";
}
.icon-globe-01::before {
  content: "\ec19";
}
.icon-globe-01-1::before {
  content: "\ec1a";
}
.icon-globe-02::before {
  content: "\ec1b";
}
.icon-globe-02-1::before {
  content: "\ec1c";
}
.icon-globe-03::before {
  content: "\ec1d";
}
.icon-globe-04::before {
  content: "\ec1e";
}
.icon-globe-05::before {
  content: "\ec1f";
}
.icon-globe-06::before {
  content: "\ec20";
}
.icon-google-chrome::before {
  content: "\ec21";
}
.icon-graduation-hat-01::before {
  content: "\ec22";
}
.icon-graduation-hat-02::before {
  content: "\ec23";
}
.icon-grid::before {
  content: "\ec24";
}
.icon-grid-01::before {
  content: "\ec25";
}
.icon-grid-02::before {
  content: "\ec26";
}
.icon-grid-03::before {
  content: "\ec27";
}
.icon-grid-dots-blank::before {
  content: "\ec28";
}
.icon-grid-dots-bottom::before {
  content: "\ec29";
}
.icon-grid-dots-horizontal-center::before {
  content: "\ec2a";
}
.icon-grid-dots-left::before {
  content: "\ec2b";
}
.icon-grid-dots-outer::before {
  content: "\ec2c";
}
.icon-grid-dots-right::before {
  content: "\ec2d";
}
.icon-grid-dots-top::before {
  content: "\ec2e";
}
.icon-grid-dots-vertical-center::before {
  content: "\ec2f";
}
.icon-hand::before {
  content: "\ec30";
}
.icon-hard-drive::before {
  content: "\ec31";
}
.icon-hash-01::before {
  content: "\ec32";
}
.icon-hash-02::before {
  content: "\ec33";
}
.icon-heading-01::before {
  content: "\ec34";
}
.icon-heading-02::before {
  content: "\ec35";
}
.icon-heading-square::before {
  content: "\ec36";
}
.icon-headphones-01::before {
  content: "\ec37";
}
.icon-headphones-02::before {
  content: "\ec38";
}
.icon-heart::before {
  content: "\ec39";
}
.icon-heart-circle::before {
  content: "\ec3a";
}
.icon-heart-hand::before {
  content: "\ec3b";
}
.icon-heart-octagon::before {
  content: "\ec3c";
}
.icon-heart-rounded::before {
  content: "\ec3d";
}
.icon-heart-square::before {
  content: "\ec3e";
}
.icon-hearts::before {
  content: "\ec3f";
}
.icon-help-circle::before {
  content: "\ec40";
}
.icon-help-hexagon::before {
  content: "\ec41";
}
.icon-help-octagon::before {
  content: "\ec42";
}
.icon-help-square::before {
  content: "\ec43";
}
.icon-hexagon-01::before {
  content: "\ec44";
}
.icon-hexagon-02::before {
  content: "\ec45";
}
.icon-home-01::before {
  content: "\ec46";
}
.icon-home-02::before {
  content: "\ec47";
}
.icon-home-03::before {
  content: "\ec48";
}
.icon-home-04::before {
  content: "\ec49";
}
.icon-home-05::before {
  content: "\ec4a";
}
.icon-home-line::before {
  content: "\ec4b";
}
.icon-home-smile::before {
  content: "\ec4c";
}
.icon-horizontal-bar-chart-01::before {
  content: "\ec4d";
}
.icon-horizontal-bar-chart-02::before {
  content: "\ec4e";
}
.icon-horizontal-bar-chart-03::before {
  content: "\ec4f";
}
.icon-hourglass-01::before {
  content: "\ec50";
}
.icon-hourglass-02::before {
  content: "\ec51";
}
.icon-hourglass-03::before {
  content: "\ec52";
}
.icon-hurricane-01::before {
  content: "\ec53";
}
.icon-hurricane-02::before {
  content: "\ec54";
}
.icon-hurricane-03::before {
  content: "\ec55";
}
.icon-ig-chevron-down::before {
  content: "\ec56";
}
.icon-ig-chevron-left::before {
  content: "\ec57";
}
.icon-ig-chevron-right::before {
  content: "\ec58";
}
.icon-ig-chevron-top::before {
  content: "\ec59";
}
.icon-ig-chevrons-down::before {
  content: "\ec5a";
}
.icon-ig-chevrons-left::before {
  content: "\ec5b";
}
.icon-ig-chevrons-right::before {
  content: "\ec5c";
}
.icon-ig-chevrons-top::before {
  content: "\ec5d";
}
.icon-image-01::before {
  content: "\ec5e";
}
.icon-image-02::before {
  content: "\ec5f";
}
.icon-image-03::before {
  content: "\ec60";
}
.icon-image-04::before {
  content: "\ec61";
}
.icon-image-05::before {
  content: "\ec62";
}
.icon-image-check::before {
  content: "\ec63";
}
.icon-image-down::before {
  content: "\ec64";
}
.icon-image-indent-left::before {
  content: "\ec65";
}
.icon-image-indent-right::before {
  content: "\ec66";
}
.icon-image-left::before {
  content: "\ec67";
}
.icon-image-plus::before {
  content: "\ec68";
}
.icon-image-right::before {
  content: "\ec69";
}
.icon-image-up::before {
  content: "\ec6a";
}
.icon-image-user::before {
  content: "\ec6b";
}
.icon-image-user-check::before {
  content: "\ec6c";
}
.icon-image-user-down::before {
  content: "\ec6d";
}
.icon-image-user-left::before {
  content: "\ec6e";
}
.icon-image-user-plus::before {
  content: "\ec6f";
}
.icon-image-user-right::before {
  content: "\ec70";
}
.icon-image-user-up::before {
  content: "\ec71";
}
.icon-image-user-x::before {
  content: "\ec72";
}
.icon-image-x::before {
  content: "\ec73";
}
.icon-inbox-01::before {
  content: "\ec74";
}
.icon-inbox-02::before {
  content: "\ec75";
}
.icon-infinity::before {
  content: "\ec76";
}
.icon-info-circle::before {
  content: "\ec77";
}
.icon-info-hexagon::before {
  content: "\ec78";
}
.icon-info-octagon::before {
  content: "\ec79";
}
.icon-info-square::before {
  content: "\ec7a";
}
.icon-intersect-circle::before {
  content: "\ec7b";
}
.icon-intersect-square::before {
  content: "\ec7c";
}
.icon-italic-01::before {
  content: "\ec7d";
}
.icon-italic-02::before {
  content: "\ec7e";
}
.icon-italic-square::before {
  content: "\ec7f";
}
.icon-key-01::before {
  content: "\ec80";
}
.icon-key-02::before {
  content: "\ec81";
}
.icon-keyboard-01::before {
  content: "\ec82";
}
.icon-keyboard-02::before {
  content: "\ec83";
}
.icon-laptop-01::before {
  content: "\ec84";
}
.icon-laptop-02::before {
  content: "\ec85";
}
.icon-layer-single::before {
  content: "\ec86";
}
.icon-layers-three-01::before {
  content: "\ec87";
}
.icon-layers-three-02::before {
  content: "\ec88";
}
.icon-layers-two-01::before {
  content: "\ec89";
}
.icon-layers-two-02::before {
  content: "\ec8a";
}
.icon-layout-alt-01::before {
  content: "\ec8b";
}
.icon-layout-alt-02::before {
  content: "\ec8c";
}
.icon-layout-alt-03::before {
  content: "\ec8d";
}
.icon-layout-alt-04::before {
  content: "\ec8e";
}
.icon-layout-bottom::before {
  content: "\ec8f";
}
.icon-layout-grid-01::before {
  content: "\ec90";
}
.icon-layout-grid-02::before {
  content: "\ec91";
}
.icon-layout-left::before {
  content: "\ec92";
}
.icon-layout-right::before {
  content: "\ec93";
}
.icon-layout-top::before {
  content: "\ec94";
}
.icon-left-indent-01::before {
  content: "\ec95";
}
.icon-left-indent-02::before {
  content: "\ec96";
}
.icon-letter-spacing-01::before {
  content: "\ec97";
}
.icon-letter-spacing-02::before {
  content: "\ec98";
}
.icon-life-buoy-01::before {
  content: "\ec99";
}
.icon-life-buoy-02::before {
  content: "\ec9a";
}
.icon-lightbulb-01::before {
  content: "\ec9b";
}
.icon-lightbulb-02::before {
  content: "\ec9c";
}
.icon-lightbulb-03::before {
  content: "\ec9d";
}
.icon-lightbulb-04::before {
  content: "\ec9e";
}
.icon-lightbulb-05::before {
  content: "\ec9f";
}
.icon-lightning-01::before {
  content: "\eca0";
}
.icon-lightning-02::before {
  content: "\eca1";
}
.icon-line-chart-down-01::before {
  content: "\eca2";
}
.icon-line-chart-down-02::before {
  content: "\eca3";
}
.icon-line-chart-down-03::before {
  content: "\eca4";
}
.icon-line-chart-down-04::before {
  content: "\eca5";
}
.icon-line-chart-down-05::before {
  content: "\eca6";
}
.icon-line-chart-up-01::before {
  content: "\eca7";
}
.icon-line-chart-up-02::before {
  content: "\eca8";
}
.icon-line-chart-up-03::before {
  content: "\eca9";
}
.icon-line-chart-up-04::before {
  content: "\ecaa";
}
.icon-line-chart-up-05::before {
  content: "\ecab";
}
.icon-line-height::before {
  content: "\ecac";
}
.icon-link-01::before {
  content: "\ecad";
}
.icon-link-02::before {
  content: "\ecae";
}
.icon-link-03::before {
  content: "\ecaf";
}
.icon-link-04::before {
  content: "\ecb0";
}
.icon-link-05::before {
  content: "\ecb1";
}
.icon-link-broken-01::before {
  content: "\ecb2";
}
.icon-link-broken-02::before {
  content: "\ecb3";
}
.icon-link-external-01::before {
  content: "\ecb4";
}
.icon-link-external-02::before {
  content: "\ecb5";
}
.icon-list::before {
  content: "\ecb6";
}
.icon-loading-01::before {
  content: "\ecb7";
}
.icon-loading-02::before {
  content: "\ecb8";
}
.icon-loading-03::before {
  content: "\ecb9";
}
.icon-lock-01::before {
  content: "\ecba";
}
.icon-lock-02::before {
  content: "\ecbb";
}
.icon-lock-03::before {
  content: "\ecbc";
}
.icon-lock-04::before {
  content: "\ecbd";
}
.icon-lock-keyhole-circle::before {
  content: "\ecbe";
}
.icon-lock-keyhole-square::before {
  content: "\ecbf";
}
.icon-lock-unlocked-01::before {
  content: "\ecc0";
}
.icon-lock-unlocked-02::before {
  content: "\ecc1";
}
.icon-lock-unlocked-03::before {
  content: "\ecc2";
}
.icon-lock-unlocked-04::before {
  content: "\ecc3";
}
.icon-log-in-01::before {
  content: "\ecc4";
}
.icon-log-in-02::before {
  content: "\ecc5";
}
.icon-log-in-03::before {
  content: "\ecc6";
}
.icon-log-in-04::before {
  content: "\ecc7";
}
.icon-log-out-01::before {
  content: "\ecc8";
}
.icon-log-out-02::before {
  content: "\ecc9";
}
.icon-log-out-03::before {
  content: "\ecca";
}
.icon-log-out-04::before {
  content: "\eccb";
}
.icon-luggage-01::before {
  content: "\eccc";
}
.icon-luggage-02::before {
  content: "\eccd";
}
.icon-luggage-03::before {
  content: "\ecce";
}
.icon-magic-wand-01::before {
  content: "\eccf";
}
.icon-magic-wand-02::before {
  content: "\ecd0";
}
.icon-mail-01::before {
  content: "\ecd1";
}
.icon-mail-02::before {
  content: "\ecd2";
}
.icon-mail-03::before {
  content: "\ecd3";
}
.icon-mail-04::before {
  content: "\ecd4";
}
.icon-mail-05::before {
  content: "\ecd5";
}
.icon-map-01::before {
  content: "\ecd6";
}
.icon-map-02::before {
  content: "\ecd7";
}
.icon-mark::before {
  content: "\ecd8";
}
.icon-marker-pin-01::before {
  content: "\ecd9";
}
.icon-marker-pin-02::before {
  content: "\ecda";
}
.icon-marker-pin-03::before {
  content: "\ecdb";
}
.icon-marker-pin-04::before {
  content: "\ecdc";
}
.icon-marker-pin-05::before {
  content: "\ecdd";
}
.icon-marker-pin-06::before {
  content: "\ecde";
}
.icon-maximize-01::before {
  content: "\ecdf";
}
.icon-maximize-02::before {
  content: "\ece0";
}
.icon-medical-circle::before {
  content: "\ece1";
}
.icon-medical-cross::before {
  content: "\ece2";
}
.icon-medical-square::before {
  content: "\ece3";
}
.icon-menu-01::before {
  content: "\ece4";
}
.icon-menu-02::before {
  content: "\ece5";
}
.icon-menu-03::before {
  content: "\ece6";
}
.icon-menu-04::before {
  content: "\ece7";
}
.icon-menu-05::before {
  content: "\ece8";
}
.icon-message-alert-circle::before {
  content: "\ece9";
}
.icon-message-alert-square::before {
  content: "\ecea";
}
.icon-message-chat-circle::before {
  content: "\eceb";
}
.icon-message-chat-square::before {
  content: "\ecec";
}
.icon-message-check-circle::before {
  content: "\eced";
}
.icon-message-check-square::before {
  content: "\ecee";
}
.icon-message-circle-01::before {
  content: "\ecef";
}
.icon-message-circle-02::before {
  content: "\ecf0";
}
.icon-message-dots-circle::before {
  content: "\ecf1";
}
.icon-message-dots-square::before {
  content: "\ecf2";
}
.icon-message-heart-circle::before {
  content: "\ecf3";
}
.icon-message-heart-square::before {
  content: "\ecf4";
}
.icon-message-notification-circle::before {
  content: "\ecf5";
}
.icon-message-notification-square::before {
  content: "\ecf6";
}
.icon-message-notification-square-1::before {
  content: "\ecf7";
}
.icon-message-plus-circle::before {
  content: "\ecf8";
}
.icon-message-plus-square::before {
  content: "\ecf9";
}
.icon-message-question-circle::before {
  content: "\ecfa";
}
.icon-message-question-square::before {
  content: "\ecfb";
}
.icon-message-smile-circle::before {
  content: "\ecfc";
}
.icon-message-smile-square::before {
  content: "\ecfd";
}
.icon-message-square-01::before {
  content: "\ecfe";
}
.icon-message-square-02::before {
  content: "\ecff";
}
.icon-message-text-circle-01::before {
  content: "\ed00";
}
.icon-message-text-circle-02::before {
  content: "\ed01";
}
.icon-message-text-square-01::before {
  content: "\ed02";
}
.icon-message-text-square-02::before {
  content: "\ed03";
}
.icon-message-x-circle::before {
  content: "\ed04";
}
.icon-message-x-square::before {
  content: "\ed05";
}
.icon-microphone-01::before {
  content: "\ed06";
}
.icon-microphone-02::before {
  content: "\ed07";
}
.icon-microphone-off-01::before {
  content: "\ed08";
}
.icon-microphone-off-02::before {
  content: "\ed09";
}
.icon-minimize-01::before {
  content: "\ed0a";
}
.icon-minimize-02::before {
  content: "\ed0b";
}
.icon-minus::before {
  content: "\ed0c";
}
.icon-minus-circle::before {
  content: "\ed0d";
}
.icon-minus-square::before {
  content: "\ed0e";
}
.icon-modem-01::before {
  content: "\ed0f";
}
.icon-modem-02::before {
  content: "\ed10";
}
.icon-monitor-01::before {
  content: "\ed11";
}
.icon-monitor-02::before {
  content: "\ed12";
}
.icon-monitor-03::before {
  content: "\ed13";
}
.icon-monitor-04::before {
  content: "\ed14";
}
.icon-monitor-05::before {
  content: "\ed15";
}
.icon-moon-01::before {
  content: "\ed16";
}
.icon-moon-02::before {
  content: "\ed17";
}
.icon-moon-eclipse::before {
  content: "\ed18";
}
.icon-moon-star::before {
  content: "\ed19";
}
.icon-mouse::before {
  content: "\ed1a";
}
.icon-move::before {
  content: "\ed1b";
}
.icon-music::before {
  content: "\ed1c";
}
.icon-music-note-01::before {
  content: "\ed1d";
}
.icon-music-note-02::before {
  content: "\ed1e";
}
.icon-navigation-pointer-01::before {
  content: "\ed1f";
}
.icon-navigation-pointer-02::before {
  content: "\ed20";
}
.icon-navigation-pointer-off-01::before {
  content: "\ed21";
}
.icon-navigation-pointer-off-02::before {
  content: "\ed22";
}
.icon-notification-box::before {
  content: "\ed23";
}
.icon-notification-text::before {
  content: "\ed24";
}
.icon-octagon::before {
  content: "\ed25";
}
.icon-opensea::before {
  content: "\ed26";
}
.icon-package::before {
  content: "\ed27";
}
.icon-package-check::before {
  content: "\ed28";
}
.icon-package-minus::before {
  content: "\ed29";
}
.icon-package-plus::before {
  content: "\ed2a";
}
.icon-package-search::before {
  content: "\ed2b";
}
.icon-package-x::before {
  content: "\ed2c";
}
.icon-paint::before {
  content: "\ed2d";
}
.icon-paint-pour::before {
  content: "\ed2e";
}
.icon-palette::before {
  content: "\ed2f";
}
.icon-paperclip::before {
  content: "\ed30";
}
.icon-paragraph-spacing::before {
  content: "\ed31";
}
.icon-paragraph-wrap::before {
  content: "\ed32";
}
.icon-passcode::before {
  content: "\ed33";
}
.icon-passcode-lock::before {
  content: "\ed34";
}
.icon-passport::before {
  content: "\ed35";
}
.icon-pause-circle::before {
  content: "\ed36";
}
.icon-pause-square::before {
  content: "\ed37";
}
.icon-pen-tool-01::before {
  content: "\ed38";
}
.icon-pen-tool-02::before {
  content: "\ed39";
}
.icon-pen-tool-minus::before {
  content: "\ed3a";
}
.icon-pen-tool-plus::before {
  content: "\ed3b";
}
.icon-pencil-01::before {
  content: "\ed3c";
}
.icon-pencil-02::before {
  content: "\ed3d";
}
.icon-pencil-line::before {
  content: "\ed3e";
}
.icon-pentagon::before {
  content: "\ed3f";
}
.icon-percent-01::before {
  content: "\ed40";
}
.icon-percent-02::before {
  content: "\ed41";
}
.icon-percent-03::before {
  content: "\ed42";
}
.icon-perspective-01::before {
  content: "\ed43";
}
.icon-perspective-02::before {
  content: "\ed44";
}
.icon-phone::before {
  content: "\ed45";
}
.icon-phone-01::before {
  content: "\ed46";
}
.icon-phone-02::before {
  content: "\ed47";
}
.icon-phone-call-01::before {
  content: "\ed48";
}
.icon-phone-call-02::before {
  content: "\ed49";
}
.icon-phone-hang-up::before {
  content: "\ed4a";
}
.icon-phone-incoming-01::before {
  content: "\ed4b";
}
.icon-phone-incoming-02::before {
  content: "\ed4c";
}
.icon-phone-outgoing-01::before {
  content: "\ed4d";
}
.icon-phone-outgoing-02::before {
  content: "\ed4e";
}
.icon-phone-pause::before {
  content: "\ed4f";
}
.icon-phone-plus::before {
  content: "\ed50";
}
.icon-phone-x::before {
  content: "\ed51";
}
.icon-pie-chart-01::before {
  content: "\ed52";
}
.icon-pie-chart-02::before {
  content: "\ed53";
}
.icon-pie-chart-03::before {
  content: "\ed54";
}
.icon-pie-chart-04::before {
  content: "\ed55";
}
.icon-piggy-bank-01::before {
  content: "\ed56";
}
.icon-piggy-bank-02::before {
  content: "\ed57";
}
.icon-pilcrow-01::before {
  content: "\ed58";
}
.icon-pilcrow-02::before {
  content: "\ed59";
}
.icon-pilcrow-square::before {
  content: "\ed5a";
}
.icon-pin-01::before {
  content: "\ed5b";
}
.icon-pin-02::before {
  content: "\ed5c";
}
.icon-placeholder::before {
  content: "\ed5d";
}
.icon-play::before {
  content: "\ed5e";
}
.icon-play-circle::before {
  content: "\ed5f";
}
.icon-play-square::before {
  content: "\ed60";
}
.icon-plus::before {
  content: "\ed61";
}
.icon-plus-circle::before {
  content: "\ed62";
}
.icon-plus-square::before {
  content: "\ed63";
}
.icon-podcast::before {
  content: "\ed64";
}
.icon-power-01::before {
  content: "\ed65";
}
.icon-power-02::before {
  content: "\ed66";
}
.icon-power-03::before {
  content: "\ed67";
}
.icon-presentation-chart-01::before {
  content: "\ed68";
}
.icon-presentation-chart-02::before {
  content: "\ed69";
}
.icon-presentation-chart-03::before {
  content: "\ed6a";
}
.icon-printer::before {
  content: "\ed6b";
}
.icon-puzzle-piece::before {
  content: "\ed6c";
}
.icon-puzzle-piece-02::before {
  content: "\ed6d";
}
.icon-qr-code-01::before {
  content: "\ed6e";
}
.icon-qr-code-02::before {
  content: "\ed6f";
}
.icon-receipt::before {
  content: "\ed70";
}
.icon-receipt-check::before {
  content: "\ed71";
}
.icon-recording-01::before {
  content: "\ed72";
}
.icon-recording-02::before {
  content: "\ed73";
}
.icon-recording-03::before {
  content: "\ed74";
}
.icon-reflect-01::before {
  content: "\ed75";
}
.icon-reflect-02::before {
  content: "\ed76";
}
.icon-refresh-ccw-01::before {
  content: "\ed77";
}
.icon-refresh-ccw-02::before {
  content: "\ed78";
}
.icon-refresh-ccw-03::before {
  content: "\ed79";
}
.icon-refresh-ccw-04::before {
  content: "\ed7a";
}
.icon-refresh-ccw-05::before {
  content: "\ed7b";
}
.icon-refresh-cw-01::before {
  content: "\ed7c";
}
.icon-refresh-cw-02::before {
  content: "\ed7d";
}
.icon-refresh-cw-03::before {
  content: "\ed7e";
}
.icon-refresh-cw-04::before {
  content: "\ed7f";
}
.icon-refresh-cw-05::before {
  content: "\ed80";
}
.icon-repeat-01::before {
  content: "\ed81";
}
.icon-repeat-02::before {
  content: "\ed82";
}
.icon-repeat-03::before {
  content: "\ed83";
}
.icon-repeat-04::before {
  content: "\ed84";
}
.icon-reverse-left::before {
  content: "\ed85";
}
.icon-reverse-right::before {
  content: "\ed86";
}
.icon-right-indent-01::before {
  content: "\ed87";
}
.icon-right-indent-02::before {
  content: "\ed88";
}
.icon-rocket-01::before {
  content: "\ed89";
}
.icon-rocket-02::before {
  content: "\ed8a";
}
.icon-roller-brush::before {
  content: "\ed8b";
}
.icon-route::before {
  content: "\ed8c";
}
.icon-rows-01::before {
  content: "\ed8d";
}
.icon-rows-02::before {
  content: "\ed8e";
}
.icon-rows-03::before {
  content: "\ed8f";
}
.icon-rss-01::before {
  content: "\ed90";
}
.icon-rss-02::before {
  content: "\ed91";
}
.icon-ruler::before {
  content: "\ed92";
}
.icon-safe::before {
  content: "\ed93";
}
.icon-sale-01::before {
  content: "\ed94";
}
.icon-sale-02::before {
  content: "\ed95";
}
.icon-sale-03::before {
  content: "\ed96";
}
.icon-sale-04::before {
  content: "\ed97";
}
.icon-save-01::before {
  content: "\ed98";
}
.icon-save-02::before {
  content: "\ed99";
}
.icon-save-03::before {
  content: "\ed9a";
}
.icon-scale-01::before {
  content: "\ed9b";
}
.icon-scale-02::before {
  content: "\ed9c";
}
.icon-scale-03::before {
  content: "\ed9d";
}
.icon-scales-01::before {
  content: "\ed9e";
}
.icon-scan::before {
  content: "\ed9f";
}
.icon-scissors-01::before {
  content: "\eda0";
}
.icon-scissors-cut-01::before {
  content: "\eda1";
}
.icon-search-lg::before {
  content: "\eda2";
}
.icon-search-md::before {
  content: "\eda3";
}
.icon-search-refraction::before {
  content: "\eda4";
}
.icon-search-sm::before {
  content: "\eda5";
}
.icon-send-01::before {
  content: "\eda6";
}
.icon-send-02::before {
  content: "\eda7";
}
.icon-send-03::before {
  content: "\eda8";
}
.icon-server-01::before {
  content: "\eda9";
}
.icon-server-02::before {
  content: "\edaa";
}
.icon-server-03::before {
  content: "\edab";
}
.icon-server-04::before {
  content: "\edac";
}
.icon-server-05::before {
  content: "\edad";
}
.icon-server-06::before {
  content: "\edae";
}
.icon-settings-01::before {
  content: "\edaf";
}
.icon-settings-02::before {
  content: "\edb0";
}
.icon-settings-03::before {
  content: "\edb1";
}
.icon-settings-04::before {
  content: "\edb2";
}
.icon-share-01::before {
  content: "\edb3";
}
.icon-share-02::before {
  content: "\edb4";
}
.icon-share-03::before {
  content: "\edb5";
}
.icon-share-04::before {
  content: "\edb6";
}
.icon-share-05::before {
  content: "\edb7";
}
.icon-share-06::before {
  content: "\edb8";
}
.icon-share-07::before {
  content: "\edb9";
}
.icon-shield-01::before {
  content: "\edba";
}
.icon-shield-02::before {
  content: "\edbb";
}
.icon-shield-03::before {
  content: "\edbc";
}
.icon-shield-dollar::before {
  content: "\edbd";
}
.icon-shield-off::before {
  content: "\edbe";
}
.icon-shield-plus::before {
  content: "\edbf";
}
.icon-shield-tick::before {
  content: "\edc0";
}
.icon-shield-zap::before {
  content: "\edc1";
}
.icon-shopping-bag-01::before {
  content: "\edc2";
}
.icon-shopping-bag-02::before {
  content: "\edc3";
}
.icon-shopping-bag-03::before {
  content: "\edc4";
}
.icon-shopping-cart-01::before {
  content: "\edc5";
}
.icon-shopping-cart-02::before {
  content: "\edc6";
}
.icon-shopping-cart-03::before {
  content: "\edc7";
}
.icon-shuffle-01::before {
  content: "\edc8";
}
.icon-shuffle-02::before {
  content: "\edc9";
}
.icon-signal-01::before {
  content: "\edca";
}
.icon-signal-02::before {
  content: "\edcb";
}
.icon-signal-03::before {
  content: "\edcc";
}
.icon-simcard::before {
  content: "\edcd";
}
.icon-skew::before {
  content: "\edce";
}
.icon-skip-back::before {
  content: "\edcf";
}
.icon-skip-forward::before {
  content: "\edd0";
}
.icon-slash-circle-01::before {
  content: "\edd1";
}
.icon-slash-circle-02::before {
  content: "\edd2";
}
.icon-slash-divider::before {
  content: "\edd3";
}
.icon-slash-octagon::before {
  content: "\edd4";
}
.icon-sliders-01::before {
  content: "\edd5";
}
.icon-sliders-02::before {
  content: "\edd6";
}
.icon-sliders-03::before {
  content: "\edd7";
}
.icon-sliders-04::before {
  content: "\edd8";
}
.icon-snowflake-01::before {
  content: "\edd9";
}
.icon-snowflake-02::before {
  content: "\edda";
}
.icon-sound-off::before {
  content: "\eddb";
}
.icon-sound-on::before {
  content: "\eddc";
}
.icon-spacing-height-01::before {
  content: "\eddd";
}
.icon-spacing-height-02::before {
  content: "\edde";
}
.icon-spacing-width-01::before {
  content: "\eddf";
}
.icon-spacing-width-02::before {
  content: "\ede0";
}
.icon-speaker-01::before {
  content: "\ede1";
}
.icon-speaker-02::before {
  content: "\ede2";
}
.icon-speaker-03::before {
  content: "\ede3";
}
.icon-speedometer-01::before {
  content: "\ede4";
}
.icon-speedometer-02::before {
  content: "\ede5";
}
.icon-speedometer-03::before {
  content: "\ede6";
}
.icon-speedometer-04::before {
  content: "\ede7";
}
.icon-square::before {
  content: "\ede8";
}
.icon-stand::before {
  content: "\ede9";
}
.icon-star-01::before {
  content: "\edea";
}
.icon-star-02::before {
  content: "\edeb";
}
.icon-star-03::before {
  content: "\edec";
}
.icon-star-04::before {
  content: "\eded";
}
.icon-star-05::before {
  content: "\edee";
}
.icon-star-06::before {
  content: "\edef";
}
.icon-star-07::before {
  content: "\edf0";
}
.icon-stars-01::before {
  content: "\edf1";
}
.icon-stars-02::before {
  content: "\edf2";
}
.icon-stars-03::before {
  content: "\edf3";
}
.icon-sticker-circle::before {
  content: "\edf4";
}
.icon-sticker-square::before {
  content: "\edf5";
}
.icon-stop::before {
  content: "\edf6";
}
.icon-stop-circle::before {
  content: "\edf7";
}
.icon-stop-square::before {
  content: "\edf8";
}
.icon-strikethrough-01::before {
  content: "\edf9";
}
.icon-strikethrough-02::before {
  content: "\edfa";
}
.icon-strikethrough-square::before {
  content: "\edfb";
}
.icon-subscript::before {
  content: "\edfc";
}
.icon-sun::before {
  content: "\edfd";
}
.icon-sun-setting-01::before {
  content: "\edfe";
}
.icon-sun-setting-02::before {
  content: "\edff";
}
.icon-sun-setting-03::before {
  content: "\ee00";
}
.icon-sunrise::before {
  content: "\ee01";
}
.icon-sunset::before {
  content: "\ee02";
}
.icon-switch-horizontal-01::before {
  content: "\ee03";
}
.icon-switch-horizontal-02::before {
  content: "\ee04";
}
.icon-switch-vertical-01::before {
  content: "\ee05";
}
.icon-switch-vertical-02::before {
  content: "\ee06";
}
.icon-table::before {
  content: "\ee07";
}
.icon-tablet-01::before {
  content: "\ee08";
}
.icon-tablet-02::before {
  content: "\ee09";
}
.icon-tag-01::before {
  content: "\ee0a";
}
.icon-tag-02::before {
  content: "\ee0b";
}
.icon-tag-03::before {
  content: "\ee0c";
}
.icon-target-01::before {
  content: "\ee0d";
}
.icon-target-02::before {
  content: "\ee0e";
}
.icon-target-03::before {
  content: "\ee0f";
}
.icon-target-04::before {
  content: "\ee10";
}
.icon-target-05::before {
  content: "\ee11";
}
.icon-terminal::before {
  content: "\ee12";
}
.icon-terminal-browser::before {
  content: "\ee13";
}
.icon-terminal-circle::before {
  content: "\ee14";
}
.icon-terminal-square::before {
  content: "\ee15";
}
.icon-text-input::before {
  content: "\ee16";
}
.icon-thermometer-01::before {
  content: "\ee17";
}
.icon-thermometer-02::before {
  content: "\ee18";
}
.icon-thermometer-03::before {
  content: "\ee19";
}
.icon-thermometer-cold::before {
  content: "\ee1a";
}
.icon-thermometer-warm::before {
  content: "\ee1b";
}
.icon-thumbs-down::before {
  content: "\ee1c";
}
.icon-thumbs-up::before {
  content: "\ee1d";
}
.icon-ticket-01::before {
  content: "\ee1e";
}
.icon-ticket-02::before {
  content: "\ee1f";
}
.icon-toggle-01-left::before {
  content: "\ee20";
}
.icon-toggle-01-right::before {
  content: "\ee21";
}
.icon-toggle-02-left::before {
  content: "\ee22";
}
.icon-toggle-02-right::before {
  content: "\ee23";
}
.icon-toggle-03-left::before {
  content: "\ee24";
}
.icon-toggle-03-right::before {
  content: "\ee25";
}
.icon-tool-01::before {
  content: "\ee26";
}
.icon-tool-02::before {
  content: "\ee27";
}
.icon-train::before {
  content: "\ee28";
}
.icon-tram::before {
  content: "\ee29";
}
.icon-transform::before {
  content: "\ee2a";
}
.icon-translate-02::before {
  content: "\ee2b";
}
.icon-trash-01::before {
  content: "\ee2c";
}
.icon-trash-02::before {
  content: "\ee2d";
}
.icon-trash-03::before {
  content: "\ee2e";
}
.icon-trash-04::before {
  content: "\ee2f";
}
.icon-trend-down-01::before {
  content: "\ee30";
}
.icon-trend-down-02::before {
  content: "\ee31";
}
.icon-trend-up-01::before {
  content: "\ee32";
}
.icon-trend-up-02::before {
  content: "\ee33";
}
.icon-triangle::before {
  content: "\ee34";
}
.icon-trophy-01::before {
  content: "\ee35";
}
.icon-trophy-02::before {
  content: "\ee36";
}
.icon-truck-01::before {
  content: "\ee37";
}
.icon-truck-02::before {
  content: "\ee38";
}
.icon-tv-01::before {
  content: "\ee39";
}
.icon-tv-02::before {
  content: "\ee3a";
}
.icon-tv-03::before {
  content: "\ee3b";
}
.icon-type-01::before {
  content: "\ee3c";
}
.icon-type-02::before {
  content: "\ee3d";
}
.icon-type-square::before {
  content: "\ee3e";
}
.icon-type-strikethrough-01::before {
  content: "\ee3f";
}
.icon-type-strikethrough-02::before {
  content: "\ee40";
}
.icon-umbrella-01::before {
  content: "\ee41";
}
.icon-umbrella-02::before {
  content: "\ee42";
}
.icon-umbrella-03::before {
  content: "\ee43";
}
.icon-underline-01::before {
  content: "\ee44";
}
.icon-underline-02::before {
  content: "\ee45";
}
.icon-underline-square::before {
  content: "\ee46";
}
.icon-upload-01::before {
  content: "\ee47";
}
.icon-upload-02::before {
  content: "\ee48";
}
.icon-upload-03::before {
  content: "\ee49";
}
.icon-upload-04::before {
  content: "\ee4a";
}
.icon-upload-cloud-01::before {
  content: "\ee4b";
}
.icon-upload-cloud-02::before {
  content: "\ee4c";
}
.icon-usb-flash-drive::before {
  content: "\ee4d";
}
.icon-user-01::before {
  content: "\ee4e";
}
.icon-user-02::before {
  content: "\ee4f";
}
.icon-user-03::before {
  content: "\ee50";
}
.icon-user-check-01::before {
  content: "\ee51";
}
.icon-user-check-02::before {
  content: "\ee52";
}
.icon-user-circle::before {
  content: "\ee53";
}
.icon-user-down-01::before {
  content: "\ee54";
}
.icon-user-down-02::before {
  content: "\ee55";
}
.icon-user-edit::before {
  content: "\ee56";
}
.icon-user-left-01::before {
  content: "\ee57";
}
.icon-user-left-02::before {
  content: "\ee58";
}
.icon-user-minus-01::before {
  content: "\ee59";
}
.icon-user-minus-02::before {
  content: "\ee5a";
}
.icon-user-plus-01::before {
  content: "\ee5b";
}
.icon-user-plus-02::before {
  content: "\ee5c";
}
.icon-user-right-01::before {
  content: "\ee5d";
}
.icon-user-right-02::before {
  content: "\ee5e";
}
.icon-user-square::before {
  content: "\ee5f";
}
.icon-user-up-01::before {
  content: "\ee60";
}
.icon-user-up-02::before {
  content: "\ee61";
}
.icon-user-x-01::before {
  content: "\ee62";
}
.icon-user-x-02::before {
  content: "\ee63";
}
.icon-users-01::before {
  content: "\ee64";
}
.icon-users-02::before {
  content: "\ee65";
}
.icon-users-03::before {
  content: "\ee66";
}
.icon-users-check::before {
  content: "\ee67";
}
.icon-users-down::before {
  content: "\ee68";
}
.icon-users-edit::before {
  content: "\ee69";
}
.icon-users-left::before {
  content: "\ee6a";
}
.icon-users-minus::before {
  content: "\ee6b";
}
.icon-users-plus::before {
  content: "\ee6c";
}
.icon-users-right::before {
  content: "\ee6d";
}
.icon-users-up::before {
  content: "\ee6e";
}
.icon-users-x::before {
  content: "\ee6f";
}
.icon-variable::before {
  content: "\ee70";
}
.icon-vector::before {
  content: "\ee71";
}
.icon-video-recorder::before {
  content: "\ee72";
}
.icon-video-recorder-off::before {
  content: "\ee73";
}
.icon-voicemail::before {
  content: "\ee74";
}
.icon-volume-max::before {
  content: "\ee75";
}
.icon-volume-min::before {
  content: "\ee76";
}
.icon-volume-minus::before {
  content: "\ee77";
}
.icon-volume-plus::before {
  content: "\ee78";
}
.icon-volume-x::before {
  content: "\ee79";
}
.icon-wallet-01::before {
  content: "\ee7a";
}
.icon-wallet-02::before {
  content: "\ee7b";
}
.icon-wallet-03::before {
  content: "\ee7c";
}
.icon-wallet-04::before {
  content: "\ee7d";
}
.icon-wallet-05::before {
  content: "\ee7e";
}
.icon-watch-circle::before {
  content: "\ee7f";
}
.icon-watch-square::before {
  content: "\ee80";
}
.icon-waves::before {
  content: "\ee81";
}
.icon-webcam-01::before {
  content: "\ee82";
}
.icon-webcam-02::before {
  content: "\ee83";
}
.icon-whale-alphie::before {
  content: "\ee84";
}
.icon-whale-average::before {
  content: "\ee85";
}
.icon-whale-buy-now-3d::before {
  content: "\ee86";
}
.icon-whale-buy-now-cart::before {
  content: "\ee87";
}
.icon-whale-buy-now-text::before {
  content: "\ee88";
}
.icon-whale-card-list::before {
  content: "\ee89";
}
.icon-whale-down::before {
  content: "\ee8a";
}
.icon-whale-eth::before {
  content: "\ee8b";
}
.icon-whale-fast::before {
  content: "\ee8c";
}
.icon-whale-high::before {
  content: "\ee8d";
}
.icon-whale-linkedin::before {
  content: "\ee8e";
}
.icon-whale-ninja::before {
  content: "\ee8f";
}
.icon-whale-oil-gas::before {
  content: "\ee90";
}
.icon-whale-star::before {
  content: "\ee91";
}
.icon-whale-super-fast::before {
  content: "\ee92";
}
.icon-whale-twitter::before {
  content: "\ee93";
}
.icon-whale-up::before {
  content: "\ee94";
}
.icon-whale-wallet::before {
  content: "\ee95";
}
.icon-wifi::before {
  content: "\ee96";
}
.icon-wifi-off::before {
  content: "\ee97";
}
.icon-wind-01::before {
  content: "\ee98";
}
.icon-wind-02::before {
  content: "\ee99";
}
.icon-wind-03::before {
  content: "\ee9a";
}
.icon-x::before {
  content: "\ee9b";
}
.icon-x-circle::before {
  content: "\ee9c";
}
.icon-x-close::before {
  content: "\ee9d";
}
.icon-x-square::before {
  content: "\ee9e";
}
.icon-youtube::before {
  content: "\ee9f";
}
.icon-zap::before {
  content: "\eea0";
}
.icon-zap-circle::before {
  content: "\eea1";
}
.icon-zap-fast::before {
  content: "\eea2";
}
.icon-zap-off::before {
  content: "\eea3";
}
.icon-zap-square::before {
  content: "\eea4";
}
.icon-zoom-in::before {
  content: "\eea5";
}
.icon-zoom-out::before {
  content: "\eea6";
}
